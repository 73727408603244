<template>
  <div class="content">
    <a-page-header style="padding: 0" title="Chart about  All user" />
    <AboutAllUser />
  </div>
</template>

<script>
// @ is an alias to /src
import { useQuery, useResult } from "@vue/apollo-composable";

import AboutAllUser from "@/components/AboutAllUser.vue";
export default {
  components: {
    AboutAllUser,
  },
};
</script>

<style lang="scss" scoped></style>
