<template>
  <div>
    <a-space :size="20">
      <span>
        Current user
        <a-dropdown :trigger="['click']">
          <template #overlay>
            <a-menu>
              <a-menu-item v-for="item in list" :key="item.id" @click="current = item">
                {{ item.name }}({{ item.id }})
              </a-menu-item>
            </a-menu>
          </template>
          <a-button>
            {{ current.name }}
            <DownOutlined />
          </a-button>
        </a-dropdown>
      </span>
      <span>
        Current Time
        <a-button @click="year -= 1" :disabled="year == thisYear - 2">Last year</a-button>
        <span class="mx-30">
          <a-typography-text strong>{{ year }}</a-typography-text>
        </span>
        <a-button @click="year += 1" :disabled="year == thisYear">Next year</a-button>
      </span>
    </a-space>

    <div class="border d-flex justify-content-between" style="width: 1280px; margin: auto">
      <AboutmeChart :year="year + ''" :id="current.id" />
    </div>
  </div>
</template>

<script>
import AboutmeChart from "./chart/AboutmeChart.vue";
import { useQuery, useResult } from "@vue/apollo-composable";

import { DownOutlined } from "@ant-design/icons-vue";
import { reactive, toRefs, ref, inject } from "vue";
import * as dayjs from "dayjs";
import { useStore } from "vuex";

export default {
  components: {
    AboutmeChart,
    DownOutlined,
  },

  setup() {
    const { $msg, gql, $filters } = inject("$");
    const thisYear = dayjs().format("YYYY");

    const v = reactive({
      year: Number(thisYear),
    });

    const store = useStore();
    const QL = gql`
      query ($page: Int, $limit: Int) {
        users(page: $page, limit: $limit, committer: true) {
          items {
            username
            name
            id
            canLogin
            isAdmin
          }
          total
        }
      }
    `;

    const committer_id = localStorage.getItem("committer_id");

    const { result, onResult, loading, onError } = useQuery(
      QL,
      () => ({
        page: 1,
        limit: 99,
      }),
      {
        fetchPolicy: "network-only",
      }
    );
    const current = ref({
      id: committer_id,
    });

    const id = useResult(
      result,
      committer_id,
      (data) => data.users.items.find((e) => e.name == store.state.name).id
    );

    const list = useResult(result, [], (data) => data.users.items);

    onResult((res) => {
      if (res.data) {
        let id = res.data.users.items.find(
          (e) => e.name == store.state.userInfo.name
        ).id;

        localStorage.setItem("committer_id", id);
        current.value = res.data.users.items.find(
          (e) => e.name == store.state.name
        );
      }
    });
    return {
      ...toRefs(v),
      thisYear,
      id,
      list,
      listloading: loading,
      current,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
