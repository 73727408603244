<template>
  <div style="width: 400px; margin: 20px auto 0px" class="d-flex">
    <div style="width: 50%">
      <a-radio-group v-model:value="mode">
        <a-radio :value="2">Lines Changed</a-radio>
        <a-radio :value="1">Commits</a-radio>
      </a-radio-group>
    </div>
    <div style="width: 50%">
      <span style="margin: 3px">Ignore Largest:</span>
      <a-input-number v-model:value="largets" :min="0" />
    </div>
  </div>

  <div id="pie"></div>
  <a-typography-title style="width: 100%; text-align: center"
    ><a-space :size="20" v-if="project">
      <div>{{ 952 }} contributions</div>
      <div>
        <StarOutlined />
        {{ project.starCount }}
      </div>
      <div><ForkOutlined /> {{ project.forksCount }}</div>
    </a-space>
  </a-typography-title>
</template>
<script>
import { reactive, toRefs, ref, inject, onMounted, watch } from "vue";
import echarts from "@/utils/echarts.js";
import { useQuery, useResult } from "@vue/apollo-composable";
import { StarOutlined, ForkOutlined } from "@ant-design/icons-vue";

import * as dayjs from "dayjs";
import { useStore } from "vuex";
var dayOfYear = require("dayjs/plugin/dayOfYear");
var weekOfYear = require("dayjs/plugin/weekOfYear");
dayjs.extend(weekOfYear);
dayjs.extend(dayOfYear);

export default {
  props: {
    id: { Type: Number },
    year: { Type: String },
  },
  components: {
    StarOutlined,
    ForkOutlined,
  },
  setup(props) {
    const { $msg, gql } = inject("$");
    const store = useStore();
    const getprojectQL = gql`
      query (
        $committerId: Int!
        $start: Int!
        $end: Int!
        $sinceDate: Int!
        $untilDate: Int!
      ) {
        eventsByCommitter(committerId: $committerId, start: $start, end: $end) {
          id
          commitCount
          createdAt
          projectName
        }

        user(committerId: $committerId) {
          issuesCount
          mergeCount
          commitCount
          projectCount
          contributionsMadeInAllProjects(
            sinceDate: $sinceDate
            untilDate: $untilDate
          ) {
            date
            commits
            additions
            deletions
            total
          }
        }
      }
    `;

    let now = new Date().getTime();

    const { result, onResult, onError, refetch } = useQuery(
      getprojectQL,
      () => ({
        committerId: parseInt(props.id),

        start: new Date(props.year).getTime(),
        end: new Date(props.year).getTime() + 1000 * 60 * 60 * 24 * 364,
        sinceDate: parseInt(dayjs(new Date(props.year)).format("YYYYMMDD")),
        untilDate: parseInt(
          dayjs(
            new Date(props.year).getTime() + 1000 * 60 * 60 * 24 * 364
          ).format("YYYYMMDD")
        ),
      })
    );

    onResult((queryResult) => {
      if (!queryResult.data) return;
      echartInit();
    });

    onError((error) => {
      $msg.error("Fetch failed 🙃");
    });

    var weeks = [];

    const mode = ref(2);
    const largets = ref(0);

    watch(mode, echartInit);
    watch(largets, echartInit);

    const days = [
      "Saturday",
      "Friday",
      "Thursday",
      "Wednesday",
      "Tuesday",
      "Monday",
      "Sunday",
    ];
    var v = [];

    const indicator = [
      { name: "Commit", max: 1000 },
      { name: "Merge Request", max: 100 },
      { name: "project", max: 100 },
      { name: "Issue", max: 1000 },
    ];

    for (let week = 0; week < 53; week++) {
      weeks.push(week + 1);
    }

    let myChart;
    function echartInit() {
      if (!result.value) return;
      let timeList = [];
      let firstDay = dayjs(props.year + "0101").valueOf();
      for (let i = 0; i < 364; i++) {
        timeList.push(firstDay + 86400000 * i);
      }

      let list = [];

      timeList.forEach((e) => {
        let week = dayjs(e).week() - 1;
        let day = dayjs(e).day();
        day = Math.abs(day - 6);
        // let target = list.find((e) => e[0] == day && e[1] == week);
        // if (target) target.push(e);
        list.push([day, week, 0, e]);
      });

      if (myChart != null && myChart != undefined) {
        myChart.dispose(); //销毁
      }

      let commit = result.value.user.contributionsMadeInAllProjects;
      let event = result.value.eventsByCommitter;

      commit = commit.filter(
        (e) =>
          e.date >= parseInt(dayjs(new Date(props.year)).format("YYYYMMDD"))
      );

      commit.forEach((item) => {
        let week = dayjs(item.date + "").week() - 1;
        let day = dayjs(item.date + "").day();
        day = Math.abs(day - 6);
        let target = list.find((e) => e[0] == day && e[1] == week);
        if (mode.value == 1) {
          target[2] = item.commits;
        } else {
          target[2] += item.total;
          target[4] = item.additions;
          target[5] = item.deletions;
        }

        target.push(item.date);
      });
      let projectList = [];

      event.forEach((e) => {
        let targetProject = projectList.find(
          (item) => item.projectName == e.projectName
        );

        if (targetProject) {
          targetProject.count += e.commitCount;
        } else {
          projectList.push({
            projectName: e.projectName,
            count: e.commitCount,
          });
        }
      });

      projectList = projectList.slice(0, 8);
      projectList.sort((a, b) => a.count - b.count);

      let { commitCount, projectCount, issuesCount, mergeCount } =
        result.value.user;

      let colors = [
        "#5470c6",
        "#91cc75",
        "#fac858",
        "#ee6666",
        "#73c0de",
        "#3ba272",
        "#fc8452",
        "#9a60b4",
        "#ea7ccc",
      ];

      let commitColors =
        mode.value == 2
          ? ["#efefef", "#d8e887", "#8cc569", "#47a042", "#1d6a23", "#236B37"]
          : // : ["#efefef", "#fee090", "#fdae61", "#f46d43", "#d73027", "#a50026"];
            ["#efefef", "#FFA500", "#FFB600", "#FFC700", "#FF9500", "#FF8C00"];
      // : ["#efefef", "#64b5f6", "#1976d2", "#1565c0", "#0d47a1"];

      function getColorIndex(i) {
        let l = 0;
        while (i >= 1) {
          i = i / 10;
          l++;
        }
        if (l > 5) return 5;
        return l;
      }

      //list 排序 抽取最高值

      list.sort((a, b) => b[2] - a[2]);

      if (largets.value) {
        list = list.slice(largets.value);
      }

      let option = {
        tooltip: {
          trigger: "item",
          formatter: function (params, ticket, callback) {
            if (params.seriesType == "bar") {
              return ` ${params.name} ${params.value} Commit`;
            } else if (params.seriesType == "radar") {
              let strlist = params.value
                .map((e, i) => {
                  let item = indicator[i].name + " : " + e;
                  return item;
                })
                .join("<br />");

              return strlist;
            } else if (mode.value == 2) {
              return `${params.value[4] || 0} additions<br /> ${
                params.value[5] || 0
              } deletions<br/>
                ${params.value[2] || 0} total <br/>
              ${dayjs(params.value[3]).format("dddd MMM D")}`;
            }

            return `${params.value[2]} commit<br /> ${dayjs(
              params.value[3]
            ).format("dddd MMM D")}`;
          },
        },
        // legend: {
        //   left: "20%",
        //   bottom: "20%",
        //   width: "5%",
        // },
        radar: {
          center: ["85%", "20%"],
          radius: ["5%", "30%"],
          splitArea: { show: false },
          splitLine: {
            show: true,
            lineStyle: {
              type: [5, 5],
              dashOffset: 5,
              color: "#0f0f0f",
            },
          },
          axisName: {
            color: "rgba(0, 0, 0, 1)",
            fontWeight: "bolder",
            fontSize: 14,
          },
          indicator,
        },

        // visualMap: {
        //   inRange: {
        //     color: ["#efefef", "#d8e887", "#8cc569", "#47a042", "#1d6a23"],
        //   },
        // },
        xAxis3D: {
          name: null,
          type: "category",
          data: weeks,
          axisLabel: { margin: 2, interval: 0 },
        },
        yAxis3D: {
          name: null,
          type: "category",
          data: days,
        },
        zAxis3D: {
          name: null,
          type: "category",
          data: v,
        },
        grid3D: {
          // top: "-10%",
          // left: "-10%",
          axisLine: { lineStyle: { opacity: 0, width: 0 } },
          axisLabel: {
            // margin: 2,
          },
          axisTick: { show: false },
          axisPointer: { show: false },
          splitLine: { show: false },
          boxHeight: 10,
          boxWidth: 250,
          boxDepth: 35,
          viewControl: {
            projection: "orthographic",
            alpha: 40,
            beta: 45,
          },
          light: {
            main: { intensity: 1, shadow: false },
            ambient: { intensity: 0.3 },
          },
        },

        xAxis: { type: "value", boundaryGap: [0, 0.01] },
        yAxis: {
          type: "category",
          data: projectList.map((e) => e.projectName),
          name: "Recently submitted projects",
          nameTextStyle: {
            align: "left",
          },
        },
        grid: {
          left: "0%",
          right: "65%",
          top: "65%",
          bottom: "3%",
          containLabel: true,
        },

        series: [
          {
            name: "Budget vs spending",
            type: "radar",
            color: "green",
            lineStyle: {
              width: 5,
            },
            symbol: "none",
            areaStyle: {
              color: "rgba(163, 207, 160)",
            },
            data: [
              {
                value: [commitCount, mergeCount, projectCount, issuesCount],
                // name: "Actual Spending",
              },
            ],
          },
          {
            minHeight: 2,
            type: "bar3D",
            data: list.map(function (item) {
              return {
                value: [item[1], item[0], item[2], item[3], item[4], item[5]],
                itemStyle: {
                  color:
                    mode.value == 1
                      ? commitColors[item[2] > 5 ? 5 : item[2]]
                      : commitColors[getColorIndex(item[2])],
                },
              };
            }),
            shading: "realistic",
            // lambertMaterial: { textureOffset: 2 },
            label: {
              fontSize: 16,
              borderWidth: 1,
            },
            bevelSmoothness: 10,
            emphasis: {
              label: {
                fontSize: 20,
                color: "#000",
                formatter: "",
              },
            },
          },
          {
            type: "bar",
            data: projectList.map((e, i) => ({
              value: e.count,
              itemStyle: {
                color: colors[i],
              },
            })),
          },
        ],
      };

      myChart = echarts.init(document.getElementById("pie"));
      myChart.setOption(option);
      // v.myChart = myChart;
    }

    onMounted(() => {
      echartInit();
      watch(result, echartInit);
    });

    const project = useResult(result, null, (data) => data.project);

    const type = ref(1);
    return {
      project,
      type,
      mode,
      largets,
    };
  },
};
</script>
<style lang="scss" scoped>
#pie {
  height: 850px;
  width: 100%;
  margin: auto;
}
</style>
